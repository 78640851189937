import React, { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { kioskStatusAtom } from 'store/globalStateAtom';
import defaultBanner from 'images/advertisement/WithdrawalDefaultBanner.png';
import PopupAD from './PopupAD';
import { useTranslation } from 'react-i18next';

function WithdrawalUrlAD() {
  const kioskStatus = useAtomValue(kioskStatusAtom);
  const adList = (kioskStatus?.adsUrls?.withdrawalUrl as string[]) ?? [];
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const { t } = useTranslation();

  const calculateNumber = () => {
    const currentHour = new Date().getHours();
    return currentHour % adList.length;
  };

  useEffect(() => {
    setCurrentBannerIndex(calculateNumber());
  }, []);

  if (adList.length === 0) {
    return (
      <div>
        <div
          className={`fixed top-0 bottom-0 left-0 right-0 z-50 w-screen bg-black bg-opacity-50`}
        >
          <div
            className={`w-screen fixed bottom-0 left-0 right-0 top-0 flex flex-col justify-end items-end`}
          >
            <div className='flex items-center justify-center w-full h-[156px] bg-mono-100'>
              <div className='w-[68px] h-[68px] rounded-full border-[12px] border-l-blue-10 border-blue-100 animate-spin'></div>
              <p className='ml-[36px] <div class="text-center font-pretendard text-[48px] font-bold leading-[72px] tracking-[-0.96px] text-[#FDFDFD]'>
                {t('refund_wait')}
              </p>
            </div>
            <PopupAD popUpUrl={defaultBanner} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {adList.map((adURL, index) => {
        if (index === currentBannerIndex) {
          return (
            <div key={adURL}>
              <div
                className={`fixed top-0 bottom-0 left-0 right-0 z-50 w-screen bg-black bg-opacity-50`}
              >
                <div
                  className={`w-screen fixed bottom-0 left-0 right-0 top-0 flex flex-col justify-center items-end`}
                >
                  <div className='flex items-center justify-center w-full h-full bg-mono-100'>
                    <div className='w-[68px] h-[68px] rounded-full border-[12px] border-l-blue-10 border-blue-100 animate-spin'></div>
                    <p className='ml-[36px] <div class="text-center font-pretendard text-[48px] font-bold leading-[72px] tracking-[-0.96px] text-[#FDFDFD]'>
                      {t('refund_wait')}
                    </p>
                  </div>
                  <PopupAD popUpUrl={adURL} />
                </div>
              </div>
            </div>
          );
        }
        return '';
      })}
    </>
  );
}

export default WithdrawalUrlAD;
