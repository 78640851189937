import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import BtnItem from "components/LanguageSelection/BtnItem";
import Layout from "components/common/Layout";
import logo from "images/common/ktp_logo_blue.png";
import ch from "images/LanguageSelection/ch.png";
import jp from "images/LanguageSelection/jp.png";
import ko from "images/LanguageSelection/ko.png";
import en from "images/LanguageSelection/en.png";
import BannerAD from "components/advertisement/BannerAD";

function LanguageSelectionPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/promotion");
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout isKtpLogo={false} isSetting>
      <img
        src={logo}
        height={78}
        width={316}
        alt="ktp_logo"
        className="mt-36"
      />
      <h2 className="mb-24 font-bold text-head-01 mt-28">
        Please select the language.
      </h2>
      <div className="flex flex-col gap-14">
        <BtnItem lang="ch" imgUrl={ch} />
        <BtnItem lang="jp" imgUrl={jp} />
        <div className="flex gap-14">
          <BtnItem lang="en" imgUrl={en} />
          <BtnItem lang="ko" imgUrl={ko} />
        </div>
      </div>
      <BannerAD />
    </Layout>
  );
}

export default LanguageSelectionPage;
